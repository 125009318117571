.spinX {
    animation: rotationX 8s infinite linear;
}

.spinY {
	animation: rotationY 4s infinite ease-out;
}

@keyframes rotationX {
	from {
	  	transform: rotate(0deg);
	}
	to {
	  	transform: rotate(359deg);
	}
}

@keyframes rotationY {
	from {
	  	transform: rotateY(0deg);
	}
	to {
	  	transform: rotateY(359deg);
	}
}